<template>
    <div class="dm_settings_page h-100">
        <!-- Navigation -->
        <header-vue></header-vue>

        <!-- Page Content -->
        <div class="container pb-5 pb-md-0">

            <div class="row dm_settings_head_wrapper pt-5">

                <div class="col-md-4 col-12 dm_settings_tab">
                    <h2 class="dm_page_tile mb-4">Settings</h2>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link py-4" id="dm-dog-details" data-toggle="pill"
                            href="#dm-pills-dog" role="tab" aria-controls="dm-pills-dog" aria-selected="true" @click="showSettingContent()"><i
                                class="fas fa-dog pr-4"></i>Dog Details</a>
                        <a class="nav-link py-4" id="dm-owner-details" data-toggle="pill"
                            href="#dm-pills-owner" role="tab" aria-controls="dm-pills-owner" aria-selected="false" @click="showSettingContent()"><i
                                class="fas fa-user pr-4"></i> Owner Details</a>
                        <a class="nav-link py-4" id="dm-friend-requests-refrence" data-toggle="pill"
                            href="#dm-pills-friend-requests" role="tab" aria-controls="dm-pills-friend-requests"
                            aria-selected="false" @click="pendingRequestList(), showSettingContent()"><i class="fas fa fa-users pr-4"></i> Friends List</a>
                        <!-- <a class="nav-link py-4" id="dm-buy-subscription" data-toggle="pill"
                            href="#dm-pills-buy-subscription" role="tab" aria-controls="dm-pills-buy-subscription"
                            aria-selected="false" @click="showSettingContent()"><i class="fas fa-money-check-alt pr-4"></i>Buy Subscription</a>
                        <a class="nav-link py-4" id="dm-subscription-details" data-toggle="pill"
                            href="#dm-pills-subscription" role="tab" aria-controls="dm-pills-subscription"
                            aria-selected="false" @click="showSettingContent()"><i class="fas fa-money-check-alt pr-4"></i> Subscription Details</a>
                        <a class="nav-link py-4" id="dm-subscription-history-details" data-toggle="pill"
                            href="#dm-pills-subscription-history" role="tab" aria-controls="dm-pills-subscription-history"
                            aria-selected="false" @click="showSettingContent()"><i class="fas fa-money-check-alt pr-4"></i> Subscription History</a> -->
                        <a class="nav-link py-4" id="dm-help" data-toggle="pill" href="#dm-pills-help"
                            role="tab" aria-controls="dm-pills-help" aria-selected="false" @click="showSettingContent()"><i class="fas fa-envelope pr-4"></i>Contact Us</a>
                        <a class="nav-link py-4" href="/privacy-policy" target="_blank"><i class="fas fa-sticky-note pr-4"></i> Privacy Policy</a>
                        <a class="nav-link py-4" href="/terms-of-use" target="_blank"><i class="fas fa-sticky-note pr-4"></i> Terms of Use</a>
                    </div>
                </div>

                <div class="col-md-8 col-12 dm_settings_content dm_hide_content d-md-block" v-if="user && user.details">
                    <i class="fas fa-chevron-left pb-3 d-md-none" @click="showSettingsTitle()"></i>
                    <div class="tab-content pt-3" id="v-pills-tabContent">
                        <div class="tab-pane show active" id="dm-pills-dog" role="tabpanel" aria-labelledby="dm-dog-details">
                            <div class="mb-5 d-flex justify-content-between">
                                <div class="dm_settings_head d-flex justify-content-center align-items-center">
                                    <div class="dm_avatar_wrapper" v-if="is_pic_show">
                                        <div class="dm_avatar" @click="chooseImg()">
                                            <img :src="API_URL+user.name" alt="">
                                        </div>
                                    </div>
                                    <div v-if="!is_pic_show">
                                      <croppa v-model="myCroppa" canvas-color="transparent" :quality="1" :prevent-white-space="true" :zoom-speed="7" :width="500" :height="500" :placeholder-font-size="38">
                                      </croppa>
                                    </div>
                                    <div class="dm_profile_details pl-4">
                                        <h3>{{ user.dog_name }}</h3>
                                        <h6 class="font-italic">{{ user.first_name }}</h6>
                                    </div>
                                </div>
                            </div>
                            <form action="#" class="dog-details" id="dm_dog_details">
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="dog_name">DOG'S NAME</label>
                                    <input type="text" class="form-control h-auto py-3 border-0" id="dog_name" v-model="user.dog_name">
                                    <span class="error_msg alert" v-if="errors.dog_name">
                                        <strong>{{ errors.dog_name }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="breed">DOG'S BREED</label>
                                    <select name="breed" class="form-control h-auto py-3 border-0">
                                        <option selected value="">Select breed of your Dog</option>
                                        <option v-for="b in breeds" :key="b.id" :value="b.id">{{ b.name }}</option>
                                    </select>
                                    <span class="error_msg alert" v-if="errors.breed">
                                        <strong>{{ errors.breed }}</strong>
                                    </span>
                                  </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group mb-4 col-md-6">
                                        <label for="gender">GENDER</label>
                                        <select id="gender" class="form-control h-auto py-3 border-0" v-model="user.gender">
                                            <option selected value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <span class="error_msg alert" v-if="errors.gender">
                                            <strong>{{ errors.gender }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group mb-4 col-md-6">
                                      <label for="dob">Date of Birth</label>
                                      <input type="text" class="form-control h-auto py-3 border-0" id="dob" v-model="user.dob">
                                      <span class="error_msg alert" v-if="errors.dob">
                                          <strong>{{ errors.dob }}</strong>
                                      </span>
                                    </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="weight">COUNTRY</label>
                                    <select class="form-control h-auto py-3 border-0" v-model="user.country_id" @change="getStateList()">
                                        <option selected value="">Select Country</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                                    </select>
                                    <span class="error_msg alert" v-if="errors.country_id">
                                        <strong>{{ errors.country_id }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="states">STATE</label>
                                    <select class="form-control h-auto py-3 border-0" v-model="user.state_id">
                                        <option selected value="">Select State</option>
                                        <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                                    </select>
                                    <span class="error_msg alert" v-if="errors.state_id">
                                        <strong>{{ errors.state_id }}</strong>
                                    </span>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="height">HEIGHT</label>
                                    <input type="text" class="form-control h-auto py-3 border-0" id="height" v-model="user.details.height">
                                    <span class="error_msg alert" v-if="errors.height">
                                        <strong>{{ errors.height }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="weight">WEIGHT</label>
                                    <input type="text" class="form-control h-auto py-3 border-0" id="weight" v-model="user.details.weight">
                                    <span class="error_msg alert" v-if="errors.weight">
                                        <strong>{{ errors.weight }}</strong>
                                    </span>
                                  </div>
                                </div>

                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="color">COLOR</label>
                                    <input type="text" class="form-control h-auto py-3 border-0" id="color" v-model="user.details.color">
                                    <span class="error_msg alert" v-if="errors.color">
                                        <strong>{{ errors.color }}</strong>
                                    </span>
                                  </div>
                                  <div class="form-group mb-4 col-md-6">
                                    <label for="certificate">CERTIFICATE</label>
                                    <div class="dm_file_upload_btn btn btn-lg position-relative h-auto py-3 w-100">
                                      <i class="fas fa-upload pr-2"></i>
                                      <span class="cert_name">Upload a new certificate</span>
                                      <input type="file" name="file" class="position-absolute dm_file_type form-control" id="certificate" @change='certChange()'/>
                                    </div>
                                    <div>
                                      <a :href="CERT_URL+user.certificate" v-if="user.certificate != ''" target="_tab" download>{{this.user.certificate}}</a>
                                    </div>
                                    <span class="error_msg alert" v-if="errors.certificate">
                                        <strong>{{ errors.certificate }}</strong>
                                    </span>
                                  </div>
                                </div>

                                <div class="form-row">
                                  <div class="form-group mb-4 col-md-6">
                                    <button type="button" class="btn btn-primary py-3 w-100 dm_settings_save_btn" @click="checkDogDetailsForm()">Update</button>
                                  </div>
                                </div>
                              </form>
                        </div>
                        <div class="tab-pane" id="dm-pills-owner" role="tabpanel" aria-labelledby="dm-owner-details">
                          <form action="#" class="owner-details" id="dm_dog_details">
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="first_name">FIRST NAME</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="first_name" v-model="user.first_name">
                                <span class="error_msg alert" v-if="errors.first_name">
                                    <strong>{{ errors.first_name }}</strong>
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="last_name">LAST NAME</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="last_name" v-model="user.last_name">
                                <span class="error_msg alert" v-if="errors.last_name">
                                    <strong>{{ errors.last_name }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="username">USERNAME</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="username" v-model="user.username">
                                <span class="error_msg alert" v-if="errors.username">
                                    <strong>{{ errors.username }}</strong>
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="email">EMAIL</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="email" v-model="user.email" readonly>
                                <span class="error_msg alert" v-if="errors.email">
                                    <strong>{{ errors.email }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="phone">PHONE</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="phone" v-model="user.phone">
                                <span class="error_msg alert" v-if="errors.phone">
                                    <strong>{{ errors.phone }}</strong>
                                </span>
                              </div>
                            </div>
                              <div class="form-row">
                                <div class="form-group mb-4 col-md-6">
                                <button type="button" class="dm_btn_dark_solid" @click="checkOwnerDetailsForm()">Update</button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="tab-pane" id="dm-pills-friend-requests" role="tabpanel" aria-labelledby="dm-friend-requests-refrence">
                          <div class="dm_user_found" v-if="requests.length != 0">
                            <div class="form-row" v-for="(users,index) in requests" v-bind:key="users.id">
                              <div class="avatar">
                                <img :src="API_URL+users.userList[0].photo.name" class="rounded-circle" alt="">
                              </div>
                              <div class="media-body pl-3">
                                <h6 class="mg-b-0">{{ users.userList[0].first_name }} {{ users.userList[0].last_name }}</h6>
                                <small class="d-block tx-color-04">{{ users.userList[0].dog_name }}</small>
                              </div>
                              <div class="form-group col-md-6 mt-md-0 mt-3">
                                <button v-if="users.blocked_by && users.blocked_by == user.id" @click="unblockChannel(users.id,index)" class="dm_btn_dark_solid py-2 px-5">UnBlock</button>
                                <button v-else-if="users.blocked_by" class="dm_btn_dark_solid py-2 px-5">Already Blocked</button>
                                <button v-else @click="blockChannel(users.id,index)" class="dm_btn_dark_solid py-2 px-5">Block</button>
                              </div>
                            </div>
                          </div>
                          <div class="form-row" v-else>
                            <div class="media-body mg-l-10">
                              <h6>No Friends Found</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="dm-pills-buy-subscription" role="tabpanel" aria-labelledby="dm-pills-buy-subscription">
                          <div class="row">
                            <div class="col-12">
                                <div class="dm_subscription_head_wrapper d-flex justify-content-center">
                                    <div class="card" style="width: 22rem;">
                                        <div class="card-body text-center py-5">
                                            <h2 class="card-title mt-3 mb-5">Subscription Plan</h2>
                                            <h3 class="mb-5">11.99$/<span class="font-size-sm"> YEAR</span></h3>
                                            <p class="card-text px-3 text-justify mb-5">upon subscribing to doggy mate users will have full access to app/website thus allowing users to view dog owner's contact information and gain the ability to communicate fully with other members who subscribed.</p>
                                            <a href="/payment" class="dm_btn_med_solid">Buy</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="dm-pills-subscription" role="tabpanel" aria-labelledby="dm-subscription-refrence" >
                          <div class="form-group" v-if="this.$parent.is_subscribed == true">
                            <div class="dm_sub_details p-4" v-if="subscription && subscription.plan">
                            <div class="row pb-4">
                              <div class="col-6">
                                  <h3>ID:</h3>
                                  <p>{{this.subscription.id}}</p>
                                </div>
                                <div class="col-6">
                                  <h3>Subscription Plan:</h3>
                                  <p>{{this.subscription.plan.nickname}}</p>
                                </div>
                              </div>
                              <div class="row pb-4">
                              <div class="col-6">
                                  <h3>Subscription Amount:</h3>
                                  <p>{{(this.subscription.plan.amount)/100}} $</p>
                                </div>
                                <div class="col-6">
                                  <h3>Subscription Interval:</h3>
                                  <p>{{this.subscription.plan.interval}}</p>
                                </div>
                              </div>
                              <div class="row pb-4">
                              <div class="col-6">
                                  <h3>Subscription Started At:</h3>
                                  <p>{{new Date(this.subscription.current_period_start*1000).toLocaleString()}}</p>
                                </div>
                                <div class="col-6">
                                  <h3>Next Payment Date:</h3>
                                  <p>{{new Date(this.subscription.current_period_end*1000).toLocaleString()}}</p>
                                </div>
                              </div>
                            <div v-if="this.subscription.cancel_at != null">
                              <div class="row pb-4">
                              <div class="col-6">
                                  <h3>Ends On:</h3>
                                  <p>{{new Date(this.subscription.cancel_at*1000).toLocaleString()}}</p>
                                </div>
                                <div class="col-6">
                                  <h3>Canceled On:</h3>
                                  <p>{{new Date(this.subscription.canceled_at*1000).toLocaleString()}}</p>
                                </div>
                              </div>
                            </div>
                            <div class="form-row">
                              <div v-if="this.subscription.cancel_at == null">
                                <button @click="cancelSubscription()" class="dm_btn_dark_solid py-2 px-5">Unsubscribe</button>
                              </div>
                              <div v-if="this.subscription.cancel_at != null">
                                <button @click="resumeSubscription()" class="dm_btn_dark_solid py-2 px-5">Resume</button>
                              </div>
                            </div>
                            </div>
                          </div>
                          <div class="form-group" v-if="this.$parent.is_subscribed == false">
                            <div class="dm_sub_details p-4">
                            <div class="row">
                              <div class="col-12 justify-content-center">
                                <h3>No Subscription available for this user currently.</h3>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="dm-pills-subscription-history" role="tabpanel" aria-labelledby="dm-subscription-history-refrence">
                          <div class="form-group" v-for="(history) in subscription_history" v-bind:key="history.id">
                            <div class="dm_sub_details p-4">
                            <div class="row pb-4">
                              <div class="col-6">
                                  <h3>ID:</h3>
                                  <p>{{history.stripe_id}}</p>
                                </div>
                              </div>
                              <div class="row pb-4">
                              <div class="col-6">
                                  <h3>Start Date:</h3>
                                  <p>{{history.created_at}}</p>
                                </div>
                                <div class="col-6">
                                  <h3>End Date :</h3>
                                  <p>{{history.ends_at}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-row" v-if="subscription_history.length == 0">
                            <div class="dm_sub_details p-4">
                            <div class="row">
                              <div class="col-12 justify-content-center">
                              <h6 class="mg-b-0">No Subscription History available for this user currently.</h6>
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="dm-pills-help" role="tabpanel" aria-labelledby="dm-help">
                          <form action="#" class="owner-details" id="dm_dog_details">
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label for="contct_first_name">FIRST NAME</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="contact_first_name" disabled v-model="contact_first_name">
                                <span class="error_msg alert" v-if="errors.contact_first_name">
                                    <strong>{{ errors.contact_first_name }}</strong>
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="contact_last_name">LAST NAME</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="contact_last_name" disabled v-model="contact_last_name">
                                <span class="error_msg alert" v-if="errors.contact_last_name">
                                    <strong>{{ errors.contact_last_name }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-12">
                                <label for="contact_username">EMAIL</label>
                                <input type="text" class="form-control h-auto py-3 border-0" id="contact_username" disabled v-model="contact_email">
                                <span class="error_msg alert" v-if="errors.contact_email">
                                    <strong>{{ errors.contact_email }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-12">
                                <label for="contact_message">MESSAGE</label>
                                <textarea type="text" class="form-control h-auto py-3 border-0" id="contact_message" v-model="contact_message"></textarea>
                                <span class="error_msg alert" v-if="errors.contact_message">
                                    <strong>{{ errors.contact_message }}</strong>
                                </span>
                              </div>
                            </div>
                            <div class="form-row" style="margin-left: 1px;margin-bottom: 15px;">
                                <div id="g-recaptcha" class="g-recaptcha" data-sitekey="6LciiVwbAAAAAL6_-r88KngKRAI3yqJLvspSynWj"></div>
                            </div>
                              <div class="form-row">
                                <div class="form-group mb-4 col-md-6">
                                <button type="button" class="dm_btn_dark_solid" @click="checkContactUsForm()">Send Mail</button>
                              </div>
                            </div>
                          </form>
                        </div>
                    </div>
                </div>

            </div>
            <!-- /.row -->

        </div>
        <!-- /.container -->
        <footer-vue></footer-vue>
    </div>
</template>

<style>
  .croppa-container {
    background-color: white;
    border-radius: 150px !important;
    box-shadow: 0px 15px 30px #00000029;
  }

  .croppa-container canvas {
    width: 150px !important;
    height: 150px !important;
    border-radius: 150px !important;
  }

  .croppa-container svg {
    height: 30px;
    width: 30px;
    top: -8px !important;
    right: 20px !important;
    border: #634E55 1px solid !important;
    background: #634E55 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
  }

  .croppa-container svg path {
    fill: #fff !important;
  }

  .dm_avatar {
    cursor: pointer;
  }
</style>

<script>
import Headervue from '../partials/header-vue'
import Footervue from '../partials/footer-vue'
import {API_PROFILE_IMAGE_URL} from '../../constants'
import {API_CERTIFICATE_URL} from '../../constants'
import Vue from 'vue';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';

Vue.use(Croppa);
Vue.component('croppa', Croppa.component);

export default {
    name: "settings",
    data: () => ({
      API_URL:API_PROFILE_IMAGE_URL,
      CERT_URL:API_CERTIFICATE_URL,
      user:[],
      breeds: [],
      countries:[],
      states:[],
      errors:[],
      requests:[],
      subscription:[],
      subscription_history:[],
      is_pic_show: true,
      myCroppa: null,
      profile_pic: '',
      contact_first_name:'',
      contact_last_name:'',
      contact_email:'',
      contact_message:'',
    }),
    components: {
      'header-vue': Headervue,
      'footer-vue': Footervue
    },
    methods: {
        async getAuthUser() {
            let responce = await this.$store.dispatch("getAuthUser");
            if (responce.status) {
                responce.data.data.details = window.$.parseJSON(responce.data.data.details);
                this.user = responce.data.data;
                // var img = new Image();
                // img.src = API_PROFILE_IMAGE_URL+responce.data.data.name;
                // this.profile_pic = img;
                this.contact_first_name = this.user.first_name;
                this.contact_last_name = this.user.last_name;
                this.contact_email = this.user.email;
                this.getStateList();
            }
        },
        async getBreedList() {
          let responce = await this.$store.dispatch("getBreedList");
          if(responce.status){
              this.breeds = responce.data.data;

              window.$("[name=breed]").select2();

              var _this = this;
              window.setTimeout(function(){
                window.$("[name=breed]").val(_this.user.breed).trigger('change');
              }, 100);
          }
        },
        async pendingRequestList() {
          let responce = await this.$store.dispatch("chatRecents");
          if(responce.status){
              this.requests = responce.data.data;
          }
        },
        async getCountryList() {
            let responce = await this.$store.dispatch("getCountryList");
            if (responce.status) {
                this.countries = responce.data.data;
            }
        },
        async getStateList() {
            let form = { country_id : this.user.country_id };
            let responce = await this.$store.dispatch("getStateList", form);
            if (responce.status) {
                this.states = responce.data.data;
            }
        },
        showSettingContent: function () {
          window.$('.dm_settings_tab').addClass('dm_hide_content');
          window.$('.dm_settings_content').removeClass('dm_hide_content');

           window.grecaptcha.render('g-recaptcha', {
              'sitekey' : '6LciiVwbAAAAAL6_-r88KngKRAI3yqJLvspSynWj'
            });
        },
        showSettingsTitle: function () {
          window.$('.dm_settings_content').addClass('dm_hide_content');
          window.$('.dm_settings_tab').removeClass('dm_hide_content');
        },
        certChange: function () {
          window.$('.cert_name').text(window.$('#certificate')[0].files[0].name);
        },
        checkDogDetailsForm: function () {
          this.errors = [];

          if (!this.user.dog_name) {
            this.errors.dog_name = 'Dog name is required.';
          }
          this.user.breed = window.parseInt(window.$("[name=breed]").select2('val'));
          if (!this.user.breed) {
            this.errors.breed = 'Dog\'s Breed is required.';
          }
          if (!this.user.gender) {
            this.errors.gender = 'Gender is required.';
          }
          if (!this.user.state_id) {
            this.errors.state_id = 'State is required.';
          }
          if (!this.user.country_id) {
            this.errors.country_id = 'Country is required.';
          }
          if (!this.user.details.height) {
            this.errors.height = 'Height is required.';
          }
          if (!this.user.details.weight) {
            this.errors.weight = 'Weight is required.';
          }
          if (!this.user.details.color) {
            this.errors.color = 'Color is required.';
          }
          // if (!this.user.certificate && window.$('#certificate').val() == '' ) {
          //   this.errors.certificate = 'Certificate is required.';
          // }

          if(Object.keys(this.errors) == 0){
            this.updateDogDetail();
          }
        },
        checkOwnerDetailsForm: function () {
          this.errors = [];

          if (!this.user.first_name) {
            this.errors.first_name = 'First name is required.';
          }
          if (!this.user.last_name) {
            this.errors.last_name = 'Last name is required.';
          }
          if (!this.user.username) {
            this.errors.username = 'Username is required.';
          }
          if (!this.user.email) {
            this.errors.email = 'Email is required.';
          }
          // if (!this.user.phone) {
          //   this.errors.phone = 'Phonenumber is required.';
          // }

          if(Object.keys(this.errors) == 0){
            this.updateOwnerDetail();
          }
        },
        async updateDogDetail() {
          if (!this.is_pic_show)
            this.generateImage();
          var _this = this;
          let form = {
            dog_name: this.user.dog_name,
            breed: this.user.breed,
            gender: this.user.gender,
            dob: this.user.dob,
            country_id: this.user.country_id,
            state_id: this.user.state_id,
            height: this.user.details.height,
            weight: this.user.details.weight,
            color: this.user.details.color,
            profile_pic: this.profile_pic,
            certificate: window.$("#certificate")[0].files,
          };
          console.log(form);

          let responce = await this.$store.dispatch("submitDogDetailsUpdateForm", form);

          window.notify(responce.data.status, responce.data.message);

          _this.getAuthUser();

          return false;
        },
        async updateOwnerDetail() {
          let form = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            username: this.user.username,
            email: this.user.email,
            phone: this.user.phone,
          };

          let responce = await this.$store.dispatch("submitOwnerDetailsUpdateForm", form);

          window.notify(responce.data.status, responce.data.message);

          return false;
        },

        async updateFriendRequest(from_id,status,index) {
          let data = {
            from_id : from_id,
            status : status
          };

          this.requests.splice(index, 1);
          let responce = await this.$store.dispatch("updateRequest", data);

          window.notify(responce.data.status, responce.data.message);

          return false;
        },

        async blockChannel(channel_id,index) {
          var _this = this;
          let data = {
            chat_channel_id : channel_id,
          };

          let responce = await this.$store.dispatch("blockChannel", data);
          if (responce.data.status == true) {
            _this.requests[index].blocked_by = _this.user.id;
            window.notify(responce.data.status, 'User Blocked successfully');
          }
        },

        async unblockChannel(channel_id,index) {
          var _this = this;
          let data = {
            chat_channel_id : channel_id,
          };

          let responce = await this.$store.dispatch("unblockChannel", data);
          if (responce.data.status == true) {
            _this.requests[index].blocked_by = null;
            window.notify(responce.data.status, 'User UnBlocked successfully');
          }
        },

        async retrieveSubscription() {
          let responce = await this.$store.dispatch("retrieveSubscription");
          if (responce.status) {
            this.subscription = responce.data.data;
          }
        },

        async retrieveSubscriptionHistory() {
          let responce = await this.$store.dispatch("retrieveSubscriptionHistory");
          if (responce.status) {
            this.subscription_history = responce.data.data;
          }
        },

        async cancelSubscription() {
          if (confirm('Are you sure you want to Unsubscribe?')) {
            let responce = await this.$store.dispatch("cancelSubscription");
            if (responce.status) {
              window.notify(responce.data.status, responce.data.message);
              this.$parent.isSubscribed();
              this.retrieveSubscription();
            }
          }
        },

        async resumeSubscription() {
          if (confirm('Are you sure you want to Resume Subscription?')) {
            let responce = await this.$store.dispatch("resumeSubscription");
            if (responce.status) {
              window.notify(responce.data.status, responce.data.message);
              this.$parent.isSubscribed();
              this.retrieveSubscription();
            }
          }
        },
        checkContactUsForm: function () {

            if(window.grecaptcha && window.grecaptcha.getResponse().length <= 0)
            {
                window.notify(false, 'Oops, you have to check the recaptcha !');
                return false;
            }
            
          this.errors = [];

          if (!this.contact_first_name) {
            this.errors.contact_first_name = 'First name is required.';
          }
          if (!this.contact_last_name) {
            this.errors.contact_last_name = 'Last name is required.';
          }
          if (!this.contact_message) {
            this.errors.contact_message = 'message is required.';
          }
          if (!this.contact_email) {
            this.errors.contact_email = 'Email is required.';
          }
          // if (!this.user.phone) {
          //   this.errors.phone = 'Phonenumber is required.';
          // }

          if(Object.keys(this.errors) == 0){
            this.submitContactUsForm();
          }
        },
        async submitContactUsForm() {
          let form = {
            first_name: this.contact_first_name,
            last_name: this.contact_last_name,
            message: this.contact_message,
            email: this.contact_email,
          };

          let responce = await this.$store.dispatch("submitContactUsForm", form);

          this.contact_message = '';
          window.notify(responce.data.status, responce.data.message);
          window.grecaptcha.reset();

          return false;
        },
        chooseImg: function() {
          this.is_pic_show = false;
          setTimeout(function(){
            window.$('canvas').trigger('click');
          }, 10);
        },
        generateImage: function() {
          let url = this.myCroppa.generateDataUrl()
          if (!url) {
            this.profile_pic = '';
          }
          else
          {
            this.profile_pic = url;
          }
        }
    },

    created() {
        var _this = this;
        this.getAuthUser();
        this.getBreedList();
        this.getCountryList();
        this.retrieveSubscription();
        this.retrieveSubscriptionHistory();
        setTimeout(function(){
          window.$('#dob').datepicker({
                format: 'yyyy-mm-dd',
                endDate: '+0d',
                autoclose: true
           });
          window.$('#dob').change(function() {
              var val = window.$(this).val();
              _this.user.dob = val;
            

          });
        }, 1000);
    }
};
</script>